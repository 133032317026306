/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// util class, is applied on <html> on fullscreen image

.overscrollPrevented {
    overscroll-behavior: contain;
}

.ProductGallerywrap {
    @include desktop {
        max-width: 550px;
    }

    @include desktop-xl {
        max-width: 570px;
    }

    @include desktop-xxl {
        max-width: 600px;
    }

    .Slider {
        &-Arrows {
            display: none;
        }

        &-Crumbs {
            @include desktop {
                display: none;
            }
        }
    }
}

.ProductGallery {
    display: flex;
    height: 502px;
    margin: 0 10px;

    @include desktop {
        width: 410px;
        margin-left: auto;
        margin-right: 0;
    }

    @include desktop-xl {
        width: 472px;
    }

    @include desktop-xxl {
        width: 502px;
    }


    @include mobile {
        height: 365px;
    }

    &-Additional {
        flex: 0 0 auto;

        @include desktop {
            position: absolute;
            top: 0;
        }

        .CarouselScroll {
            @include desktop {
                display: block;
                margin-top: 0;
            }

            &-Content {
                @include desktop {
                    display: block;
                    max-height: 448px;
                }
            }
        }

        .CarouselScrollItem {
            @include desktop {
                margin-right: 0;
                margin-bottom: 10px;
            }

            &:first-child {
                @include desktop {
                    margin-left: 0;
                }
            }
        }

        .CarouselScrollArrow {
            @include desktop {
                margin-left: 28px;
                margin-bottom: 10px;

                @include gallery-next-icon;
            }

            svg {
                @include desktop {
                    display: none;
                }
            }

            &_isNextArrow {
                @include desktop {
                    margin-bottom: 0;
                }
            }
        }

        @include mobile {
            display: none;
        }

        &_isImageZoomPopupActive {
            position: absolute;
            inset-block-end: 20px;
            inset-inline-start: 20px;

            .CarouselScrollItem {
                &_isActive {
                    border-width: 2px;
                    filter: opacity(1);
                }
            }

            .CarouselScrollArrow {
                display: none;
            }
        }

        &_isWithEmptySwitcher {
            height: 66px;
        }
    }

    &-SliderWrapper {
        flex: 1;

        .Slider {
            &-Wrapper {
                border: 1px solid var(--primary-divider-color);
            }
        }
    }

    &-Slider {
        height: 100%;
        opacity: var(--sliderOpacity);

        @include mobile {
            padding-bottom: 25px;
        }

        &_isImageZoomPopupActive {
            cursor: pointer;
            width: 50%;
            margin: 0 auto;
        }

        &_isZoomInCursor {
            cursor: zoom-in;
        }

        .Image-Image {
            position: unset;
        }
    }

    .react-transform-component,
    .react-transform-element,
    &-SliderImage {
        height: 100%;
        width: 100%;
    }
}
