/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, .7);
    --product-card-reviews-background: rgba(243, 243, 243, .55);
    --product-card-wishlist-button-background: rgba(243, 243, 243, .78);
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(--product-card-wishlist-button-background);
    --product-card-compare-button-color: var(--product-card-wishlist-button-color);
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 32px;
    --product-card-background: var(--color-gray5);
    --option-margin-block: 0 12px;
    --option-margin-inline: 0 12px;
}

.ProductCard {
    padding-inline-start: 0;
    min-width: 0;
    display: flex;
    align-items: stretch;

    &:hover {
        .ProductCard {
            &-Figure {
                .Image-Image {
                    @include desktop {
                        transition: all .5s;
                        opacity: 0.6;
                    }
                }
            }

            &-Name {
                @include desktop {
                    text-decoration: underline;
                }
            }

            &-ProductActions {
                .ProductWishlistButton-Button {
                    @include desktop {
                        opacity: 1;
                        visibility: visible;
                        transition: $transition1;
                    }
                }
            }
        }
    }

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;
    }

    .ProductConfigurableAttributes {
        &-Title {
            display: none;
        }
    }

    &-ConfigurationNotice {
        opacity: 0.3;
        font-weight: normal;
        margin-block-start: -10px;
        margin-block-end: 10px;
    }

    &-Content {
        padding-block-start: 10px;
        display: flex;
        flex-direction: column;

        @include mobile {
            min-height: 98px;
        }

        &_layout_list {
            justify-content: space-between;
            margin-inline-start: 20px;
            max-width: 325px;

            .ProductCard {
                &-ActionWrapper {
                    align-items: center;
                    display: flex;

                    button {
                        margin-inline-end: 10px;
                    }
                }

                &-ProductActions {
                    flex-direction: row;
                    position: static;

                    > div {
                        margin-block-end: 0;
                    }

                    .ProductCompareButton-Button {
                        height: var(--compare-button-size);
                        width: var(--compare-button-size);
                    }
                }

                &-OutOfStock {
                    p {
                        margin-inline-end: 10px;
                    }
                }

                &-PriceWrapper {
                    flex-grow: unset;
                }

                &-WishListButton {
                    height: var(--wishlist-heart-size);
                    width: var(--wishlist-heart-size);
                }
            }
        }
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name {
        @include h6;

        margin: 0 0 5px;
        height: 38px;
        overflow: hidden;
    }

    &-Brand {
        font-weight: 400;
        margin-block-end: 4px;
        opacity: .5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: var(--product-card-brand-line-height);
        line-height: var(--product-card-brand-line-height);
        font-size: 12px;

        @include desktop {
            line-height: 16px;
        }
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &-Picture {
        padding-block-end: 92%;
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block-end: 0;
        margin: auto;
        inset-block-start: 0;
    }

    &-FigureReview {
        background: var(--product-card-background);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 0 20px;
    }

    &-Reviews {
        --product-rating-background: #ffff;

        width: 100%;
        letter-spacing: normal;
        min-height: 15px;

        @include mobile {
            width: 100%;
        }

        .ProductReviewRating {
            &-Counter {
                display: none;
            }
        }
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        min-height: calc(var(--product-card-color-size) + 5px);
    }

    &-Color,
    &-Image {
        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 0;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &-Price {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .ProductPrice {
            &-PriceBottom {
                width: 100%;
                order: 2;
                padding-top: 20px;
            }

            &-Price {
                padding-right: 5px;
            }

            &-TierPrice {
                order: 1;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: $letter-spacing1;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 10px;
        margin-block-end: 0;
    }

    .ProductPrice {
        &-PriceBadge {
            font-size: 12px;
        }
    }

    &-Figure {
        flex-grow: 1;

        img {
            width: 100%;
        }
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 10px 110px;
        background: var(--product-card-background);
    }

    &-ProductActions {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        flex-direction: column;
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    &-Footer {
        align-items: center;
        display: flex;
        width: 100%;
        padding-block-start: 15px;

        .AddToCart {
            --button-height: 35px;
            --button-padding: 0;

            @include mobile {
                --default-button-font-size: 14px;
                --default-button-letter-spacing: $letter-spacing1;
            }

            width: 100%;

            &:hover {
                @include desktop {
                    span {
                        color: var(--hollow-button-hover-color);
                    }
                }
            }

            span {
                color: var(--hollow-button-color);
            }
        }
    }

    &-AddToCart {
        --button-height: 35px;
        --button-padding: 0;

        @include mobile {
            --default-button-font-size: 14px;
            --default-button-letter-spacing: $letter-spacing1;
        }

        width: 100%;
    }

    &-OutOfStock {
        --button-height: 35px;
        --button-padding: 0;

        @include mobile {
            --default-button-font-size: 14px;
            --default-button-letter-spacing: $letter-spacing1;
        }

        align-items: center;
        display: flex;
        width: 100%;
    }

    &-VisibleOnHover {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        position: absolute;
        bottom: 10px;
        left: 0;
        padding: 0 10px;
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }

    .ProductWishlistButton {
        &-Button {
            width: 25px;
            height: 25px;
            line-height: 25px;
            box-shadow: $box-shadow4;
            border-radius: 50%;
            background-color: var(--body-background-color);

            @include desktop {
                opacity: 0;
                visibility: hidden;
            }

            .ProductWishlistButton-Heart {
                display: flex;
                align-items: center;
                margin: 3px 0 0;

                &::before {
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: $font-weight-semibold;
                }
            }
        }
    }

    &-SalesLabel {
        position: absolute;
        z-index: 1;
        left: -10px;
        top: -10px;
        max-width: 116px;

        @include mobile {
            top: -15px;
        }
    }

    &-Author {
        color: var(--color-gray2);
        font-size: 12px;
        line-height: 15px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing;
        margin-bottom: 5px;
    }
}
