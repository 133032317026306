/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductPage {
    @include tablet {
        padding-top: 0;
        margin-top: 0;
    }

    @include mobile {
        padding-bottom: 20px;
        margin-top: 0;
        margin-bottom: calc(var(--footer-total-height) + 40px);
    }

    .ContentWrapper {
        @include mobile {
            padding: 0;
        }
    }

    &-Wrapper {
        display: grid;
        grid-template-rows: min-content min-content;
        padding-top: 24px;

        @include desktop {
            grid-template-areas: '. actions';
            padding-bottom: 60px;
            padding-top: 0;
            grid-template-columns: 510px auto;
            grid-column-gap: 2rem;
        }

        @include desktop-xl {
            grid-template-columns: 570px auto;
            grid-column-gap: 4rem;
        }

        @include desktop-xxl {
            grid-template-columns: 600px auto;
        }

        @include mobile {
            grid-template-columns: 100%;
            padding: 0;
        }

        .ProductActions {
            @include desktop {
                grid-area: actions;
                max-width: 570px;
                padding-left: 0;
                padding-right: 0;
            }
        }

        > .RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }

    .BookSeriesSlider {
        &-Placeholder {
            margin-left: auto;
            margin-right: auto;
            display: grid;
        }

        &-PlaceholderItems {
            grid-template-columns: repeat(2, 1fr);
            display: grid;
        }

        @include desktop {
            margin-bottom: 82px;

            &-Placeholder {
                max-width: var(--content-wrapper-width);
                margin-bottom: 82px;
            }

            &-PlaceholderItems {
                grid-template-columns: repeat(5, 1fr);
            }
        }

        h2 {
            @include mobile {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .react-multi-carousel-track {
            @include mobile {
                padding-left: 5px;
            }
        }
    }

    &_ProductTypeGiftcard {
        .ProductInformationWrap {
            display: none;
        }

        .ProductActions {
            form {
                display: flex;
                flex-direction: column;

                @include desktop {
                    flex-wrap: wrap;
                    flex-direction: row;
                }

                input {
                    width: 100%;
                }

                .Field:first-of-type {
                    margin-top: 0;

                    @include desktop {
                        margin-top: 5px;
                    }
                }
            }

            .GiftCardProductOptions {
                &-FieldOuterWrapper {
                    margin-bottom: 25px;

                    .Field-Wrapper {
                        margin-top: 0;
                    }

                    &_isOtherAmountSelected {
                        @include desktop {
                            width: 50% !important;
                        }

                        .FieldSelect-Clickable {
                            @include desktop {
                                width: 100% !important;
                            }
                        }
                    }

                    @include desktop {
                        width: 50%;
                    }
                }

                &-OpenAmounts {
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: $font-weight-regular;
                    letter-spacing: $letter-spacing1;
                    color: var(--secondary-light-color);
                    margin-top: 5px;
                }

                &-FieldOuterWrapper_AmountinPHP,
                &-FieldOuterWrapper_AmountinUSD,
                &-FieldOuterWrapper_Amount {
                    @include desktop {
                        border-bottom: 1px solid var(--secondary-divider-color);
                        padding-bottom: 25px;
                    }
                }

                &-FieldOuterWrapper_Message {
                    width: 100%;

                    textarea {
                        width: 100%;
                        min-height: 150px;

                        @include desktop {
                            min-height: 130px;
                        }
                    }
                }

                &-FieldOuterWrapper_AmountinPHP,
                &-FieldOuterWrapper_AmountinUSD {
                    order: -1;
                    border-bottom: 1px solid var(--secondary-divider-color);
                    padding-bottom: 30px;
                }

                &-FieldOuterWrapper_Amount {
                    order: -2;
                    margin-top: 20px;

                    .FieldSelect {
                        &::before {
                            border: 0;
                            height: 0;
                        }
                    }

                    @include desktop {
                        margin-top: 0;
                        width: 100%;
                    }

                    .FieldSelect-Clickable {
                        @include desktop {
                            width: 50%;
                        }
                    }

                    .FieldSelect-Options {
                        @include desktop {
                            max-width: 280px;
                        }
                    }
                }

                &-FieldOuterWrapper_Amount,
                &-FieldOuterWrapper_SenderName,
                &-FieldOuterWrapper_RecipientName {
                    @include desktop {
                        padding-right: 10px;
                    }
                }

                &-FieldOuterWrapper_AmountinPHP,
                &-FieldOuterWrapper_AmountinUSD,
                &-FieldOuterWrapper_RecipientEmail,
                &-FieldOuterWrapper_SenderEmail {
                    @include desktop {
                        padding-left: 10px;
                    }
                }
            }

            &-SocialWrapper {
                margin-bottom: 60px;

                @include desktop {
                    margin-bottom: 0;
                }
            }
        }

        .ProductReviews {
            .Goodreads {
                &-Rating {
                    display: none;
                }
            }
        }
    }

    &-LinkedProducts {
        .ProductListWidget {
            &_carousel {
                max-width: var(--content-wrapper-width);
                margin-left: auto;
                margin-right: auto;

                @include mobile {
                    padding-bottom: 60px;
                }

                @include desktop {
                    padding-bottom: calc(var(--header-nav-height) - 5px);
                }

                h2 {
                    @include mobile {
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    @include desktop {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
    
                    @include desktop-xxl {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }

                .react-multi-carousel-list {
                    @include desktop {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}

.Header-Wrapper_name_pdp {
    @include mobile {
        margin-bottom: 20px;
    }
}

.Header_name_pdp {
    @include mobile {
        position: static;
    }

    .Header-Nav {
        &::before {
            @include mobile {
                background: url(./images/header-logo.svg) no-repeat;
                background-size: contain;
                width: 203px;
                height: 30px;
                content: "";
                display: block;
                margin: 0 auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .Header-Title {
            @include mobile {
                display: none;
            }
        }
    }
}
