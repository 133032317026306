/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-rating-background: #979797;
    --product-rating-filled-background: var(--primary-base-color);
    --product-rating-size: 18px;

    @include mobile {
        --product-rating-size: 21px;
    }
}

.ProductReviewRating {
    --percentage: 0%;

    display: flex;
    align-items: flex-end;
    line-height: normal;
    font-size: 13px;

    &::before {
        content: '\f005 \f005 \f005 \f005 \f005';
        font-family: $icomoon-font-family;
        letter-spacing: 2px;
        background: linear-gradient(
            90deg,
            var(--color-yellow2) var(--percentage),
            var(--color-grey13) var(--percentage)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &_isLoading {
        &::before {
            background-image: var(--placeholder-image);
            background-size: var(--placeholder-size);
            animation: var(--placeholder-animation);
        }
    }

    &-Counter {
        display: inline-block;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: $letter-spacing;
        color: var(--color-gray2);
        font-weight: $font-weight-regular;
        margin-left: 6px;
    }
}
