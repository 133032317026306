/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductAttributes {
    font-size: 14px;
    line-height: 20px;

    &-Wrapper {
        padding: 40px 10px;
        background: var(--color-grey14);
        margin-bottom: 60px;

        @include desktop {
            padding: 50px 20px 50px;
            margin-bottom: 0;
        }

        @include desktop-xl {
            padding: 50px 40px 50px;
        }

        @include desktop-xxl {
            padding: 50px 0 50px 40px;
        }
    }


    &-AttributeBlock {
        @include desktop {
            max-width: 600px;
        }
    }

    &-Placeholder {
        margin-top: 24px;

        @include mobile {
            margin-top: 28px;
        }

        span {
            display: block;
            margin-bottom: 12px;

            @include mobile {
                margin-bottom: 14px;
            }
        }
    }

    &-Group {
        margin: 0;
        padding: 0 0 20px 0;
    }

    &-Attributes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: flex-start;

        dt,
        dd {
            background: var(--color-white);
            padding: 10px 15px;
        }

        dt:nth-of-type(2n),
        dd:nth-of-type(2n) {
            background: var(--color-gray4);
        }

        dd:nth-child(odd) {
            background: red;
        }
    }

    &-ValueLabel,
    &-AttributeLabel {
        flex: 0 0 50%;
        overflow: hidden;
        padding-block: 16px;
        text-overflow: ellipsis;
        height: 100%;
        font-size: 12px;
        line-height: 15px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing;
        color: var(--color-gray1);
    }

    &-ValueLabel {
        line-height: 18px;
        letter-spacing: $letter-spacing1;
    }

    &-Image {
        padding-bottom: 40%;
        mix-blend-mode: multiply;
        min-width: 360px;
        background: none;

        @include mobile {
            min-width: 420px;
            display: none;
        }
    }

    &-AttributeLabel_attribute_label_Editorial,
    &-ValueLabel_attribute_label_Editorial {
        display: none;
    }
}
