/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductReviews {
    width: 100%;
    margin-bottom: 80px;

    @include mobile {
        margin-bottom: 60px;
        padding-left: 10px;
        padding-right: 10px;
    }

    @include desktop {
        z-index: 1;
    }

    h2 {
        width: 100%;
        margin-bottom: 25px;
    }

    &-Popup {
        &.Popup {
            .Popup-CloseBtn {
                right: 0;
                top: 0;
            }
        }
    }

    &-Wrapper {
        padding: 0;

        @include desktop {
            padding: 36px 12px;
        }
    }

    &-Noreview {
        margin-bottom: 0;
    }

    &-Summary {
        align-self: stretch;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &-SummaryRating {
        margin-top: 0;

        .ProductReviewRating-Counter {
            display: none;
        }
    }

    .ProductReviewRating {
        align-items: center;
        margin-right: 6px;
    }

    & &-Button {
        margin-top: 20px;

        @include desktop {
            margin-top: 0;
            margin-left: 20px;
        }
    }

    &-SummaryDetails {
        display: inline-block;
        font-size: 12px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing;
        color: var(--color-gray2);
        line-height: 15px;
        margin-bottom: 0;

        span {
            padding-left: 3px;
        }
    }

    .gr_review_container {
        font-size: 16px;
    }

    .Goodreads {
        &-Notice {
            @include h6;

            display: block;
            margin-top: 5px;
        }

        &-Rating {
            margin-bottom: 60px;
    
            @include desktop {
                margin-bottom: 80px;
            }
    
            iframe {
                width: 100%;
                height: 250px;
                overflow-y: scroll;
            }
        }
    }
}
