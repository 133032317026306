/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductInformationWrap {
    display: grid;

    @include desktop {
        grid-template-columns: 550px auto;
        margin-bottom: 80px;
        background: var(--color-grey14);
    }

    @include desktop-xl {
        grid-template-columns: repeat(2, 1fr);
    }
}

.ProductInformation_isDescriptionEmpty.ProductInformation_isEditorialReviewsEmpty {
    display: none;
}

.ProductInformation_isDescriptionEmpty.ProductInformation_isEditorialReviewsEmpty ~ div {
    @include desktop {
        transform: translate(50%, 0);
    }

    .ProductAttributes-AttributeBlock {
        @include desktop {
            max-width: none;
        }
    }
}

.ProductInformation {
    font-size: 14px;
    line-height: 20px;
    background: var(--color-gray5);
    padding: 40px 10px 40px 10px;

    @include desktop {
        padding-top: 50px;
    }

    &_isLoading {
        visibility: hidden;
    }

    &_isDescriptionEmpty {
        .ProductInformation-Title {
            display: none;
        }
    }

    &-editorialReview {
        &:empty {
            display: none;
        }
    }

    &_isEditorialReviewsEmpty {
        .ProductInformation-EditorTitle {
            display: none;
        }
    }

    h2 {
        margin-bottom: 20px;

        @include desktop {
            min-width: 600px;
        }
    }

    .product-video {
        margin-top: 5px;

        iframe {
            width: 100%;
            min-height: 192px;
        }

        @include desktop {
            iframe {
                width: 450px;
                height: 254px;
            }
        }
    }

    &-EditorTitle {
        margin-bottom: 20px;
    }

    &-Wrapper {
        padding: 0;

        @include desktop {
            max-width: 640px;
            padding: 0 40px 0 0;
        }

        @include desktop-xl {
            float: right;
        }
    }

    &-Placeholder {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }

        span {
            display: block;
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }

    &-Content {
        margin-bottom: 30px;
    }

    &-Description {
        @include default-list;

        @include mobile {
            line-height: 20px;
        }
    }
}
