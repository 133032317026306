/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductConfigurableAttributes {
    font-size: 14px;
    line-height: 20px;

    &-SwatchList,
    &-DropDownList {
        text-align: center;
    }

    &-Title {
        font-weight: 700;
        margin-block: 24px 12px;
    }

    &-SelectedOptionLabel {
        font-weight: normal;
        opacity: 0.7;
        padding-inline-start: 17px;
    }

    &-SwatchList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        padding-inline-start: 3px;
        padding-block-start: 3px;
    }

    &-DropDownList {
        width: 100%;
    }

    &-Placeholder {
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        height: 20px;
        width: 100%;
        margin-block: var(--option-margin-block);
        margin-inline: var(--option-margin-inline);
        
        &:last-of-type {
            margin-bottom: 25px;
        }
    }
}
