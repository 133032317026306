/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-review-item-border: var(--color-gray4);
}

.ProductReviewItem {
    margin: 0;
    word-break: break-word;
    padding: 25px 20px;
    border: 1px solid var(--product-review-item-border);

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        content: none;
    }

    &-ReviewTitle {
        width: 100%;
        font-weight: 700;
        grid-area: title;
    }

    &-Content {
        grid-area: content;
    }

    &-RatingSummary {
        margin: 15px 0 20px 0;
    }

    &-RatingSummaryItem {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &-ReviewContent {
        flex: 1 0 0;

        @include mobile {
            flex: 0 0 auto;
            width: 100%;
            order: 3;
        }

        @include tablet {
            flex: auto;
            order: 15;
        }

        .TextPlaceholder {
            display: block;
        }
    }

    &-ReviewAuthor {
        margin-top: 15px;
        margin-bottom: 0;
        font-size: 12px;
        color: var(--color-gray2);
        font-weight: $font-weight-regular;

        strong {
            font-weight: $font-weight-regular;
            letter-spacing: $letter-spacing1;
            color: var(--secondary-light-color);
            text-transform: capitalize;
        }
    }

    &-ReviewDetails {
        text-align: justify;
    }

    .ProductReviewRating-Counter {
        display: none;
    }

    &-RatingItem {
        display: none;
    }
}
