/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductReviewForm {
    &-Wrapper {
        @include desktop {
            display: grid;
        }
    }

    &-ProductName {
        font-weight: $font-weight-bold;
        font-size: 24px;
    }

    &-Content {
        input,
        textarea {
            font-size: 12px;
        }
    }

    &-Rating {
        margin-top: 12px;
        border: 0;
        direction: rtl;
        text-align: left;
        /* stylelint-disable-next-line unknownProperties, property-no-unknown */
        white-space-collapse: discard; // alternative of `font-size: 0`

        input {
            cursor: pointer;
        }
    }

    &-Legend {
        font-weight: $font-weight-bold;
        font-size: 13px;
        margin-bottom: 5px;
        text-align: left;
    }

    & &-Button {
        margin-top: 20px;
        width: 100%;
    }

    input,
    textarea {
        width: 100%;
    }
}
