/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductWishlistButton {
    --loader-scale: .5;

    display: inline-block;
    height: var(--wishlist-heart-size);
    width: var(--wishlist-heart-size);

    .Loader {
        border-radius: 50%;
    }

    &-Button {
        &.Button {
            background-color: Var(--color-white);
            border: none;
            padding: 0;
    
            &:hover {
                @include desktop {
                    background-color: Var(--color-white);
                    padding: 0;
                }

                .ProductWishlistButton-Heart {
                    &::before {
                        @include desktop {
                            transition: $transition2;
                            color: var(--primary-base-color);
                        }
                    }
                }
            }

            &_isHollow {
                &:not([disabled]):hover {
                    @include desktop {
                        background-color: Var(--color-white);
                    }
                }
            }
        }

        &:hover {
            .ProductWishlistButton-Heart {
                &::before {
                    @include desktop {
                        color: var(--primary-base-color);
                    }
                }
            }
        }

        span {
            display: none;
        }

        &_isInWishlist {
            .ProductWishlistButton-Heart {
                @include wishlist-icon-active;
            }
        }
    }

    &-Heart {
        @include wishlist-icon;

        &::before {
            color: var(--primary-light-color);
        }
    }
}
