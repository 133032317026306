/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-list-load-button-background: var(--secondary-base-color);
}

.ProductList {
    @include desktop {
        grid-column: 2;
        padding-block-end: 24px;
    }

    &-ProductsMissing {
        text-align: center;

        @include desktop {
            max-width: 584px;
            text-align: left;
            margin-top: 10px;
        }

        &SubTitle {
            margin-bottom: 20px;

            @include mobile {
                display: none;
            }
        }

        img {
            width: 120px;
            margin-bottom: 30px;

            @include mobile {
                margin-top: 49px;
                margin-bottom: 40px;
            }
        }

        h3 {
            margin-bottom: 20px;
        }

        p {
            @include mobile {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    &-More {
        min-height: 36px;

        @include mobile {
            min-height: 42px;
        }
    }

    &-LoadButtonContainer {
        text-align: center;
    }

    &-LoadButton {
        @include mobile {
            margin-block-end: 20px;
        }
    }

    &_isLoading {
        &.ProductListWidget_grid {
            .ProductListWidget-Page_grid {
                .ProductCard {
                    @include desktop {
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8) {
                            display: none;
                        }
                    }
                }
            }
        }

        &.ProductListWidget_carousel {
            .ProductListWidget-Page_carousel {
                display: grid;

                .ProductCard {
                    @include mobile {
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8) {
                            display: none;
                        }
                    }

                    @include desktop {
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
