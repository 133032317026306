/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductLinks {
    width: 100%;
    margin-bottom: 60px;

    @include desktop {
        grid-column: 1 / span 2;
        margin-bottom: 50px;
    }

    &:last-of-type {
        @include desktop {
            margin-bottom: 40px;
        }
    }

    &-Wrapper {
        padding-block: 0;

        @include mobile {
            padding-inline: 0;
        }
    }

    &-List {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: 1fr 1fr;

        @include mobile {
            grid-gap: 14px;
        }

        @include desktop {
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 24px;
            justify-content: center;
        }

        @include narrow-desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &-Title {
        padding: 0 10px;
    }

    .Image_imageStatus_1 {
        mix-blend-mode: multiply;
    }
}
