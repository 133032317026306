/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --price-color: var(--primary-light-color);
    --price-with-discount-color: #b10d0d;
    --price-discount-color: #0A0903;
}

.ProductPrice {
    color: var(--price-color);
    font-weight: $font-weight-semibold;
    font-size: 17px;
    font-style: normal;
    line-height: 20px;
    margin-block-end: 0;
    margin-block-start: 0;
    vertical-align: middle;
    letter-spacing: $letter-spacing2;

    @include desktop {
        min-height: 40px;
    }

    @include mobile {
        font-size: 18px;
    }

    &-Price {
        text-decoration: none;
        display: inline-flex;
        align-items: baseline;
        flex-wrap: wrap;
    }

    del {
        font-size: 12px;
        letter-spacing: $letter-spacing;
        font-weight: $font-weight-regular;
        color: var(--color-gray2);
        display: inline-block;
    }

    &-HighPrice {
        color: var(--price-color);
    }

    &-PriceValue {
        @include h5;

        line-height: 20px;
    }

    &-TierPrice {
        @include paragraph-text;

        color: var(--secondary-light-color);
        margin-bottom: 0;

        strong {
            color: var(--secondary-light-color);
            font-weight: $font-weight-regular;
        }
    }

    &-PriceBottom {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }

    &-OldPrice {
        display: flex;
        flex-wrap: wrap;

        .ProductActions {
            &-discountPer {
                color: var(--color-orange4);
                margin-left: 5px;
                font-size: 12px;
            }
        }
    }

    &, &-BundleFrom, &-BundleTo {
        &_hasDiscount {
            .ProductPrice {
                &-PriceBadge {
                    margin-block-start: 5px;
                    margin-block-end: -5px;
                }
            }
        }
    }

    &-Placeholder {
        &::after {
            content: 'aaaaa';
        }
    }

    &-SubPrice {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-block-start: 4px;

        @include desktop {
            line-height: 16px;
        }
    }

    &-PriceBadge {
        font-size: 14px;
        font-weight: 400;
        margin-inline-end: 4px;
        white-space: nowrap;

        @include desktop {
            line-height: 20px;
        }
    }
}
