/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --cart-item-actions-color: var(--primary-light-color);
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.ProductActions {
    padding: 0 10px;

    .pdp-info-wrap {
        background: var(--color-gray5);
        margin-top: 10px;
        margin-bottom: 40px;
        padding: 0 15px;

        @include mobile {
            margin-top: 20px;
        }

        @include desktop {
            margin-bottom: 0;
        }

        .pagebuilder-column-group {
            border-top: 1px solid var(--primary-divider-color);

            .pagebuilder-column {
                align-items: center;
                display: flex !important;
                width: auto !important;
                flex-direction: row !important;
            }

            &:first-child {
                border-top: 0;
            }

            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                max-width: none !important;
            }

            @include mobile {
                .pagebuilder-mobile-hidden {
                    display: none;
                }
            }

            @include desktop {
                .pagebuilder-mobile-only {
                    display: none;
                }
            }
        }

        p {
            font-size: 12px;
            line-height: 18px;
            font-weight: $font-weight-regular;
            letter-spacing: $letter-spacing1;
            color: var(--secondary-light-color);
            padding: 15px 0;
            margin-bottom: 0;

            a {
                font-size: 12px;
                font-weight: $font-weight-medium;
                letter-spacing: $letter-spacing10;
                color: var(--color-gray9);

                &:hover {
                    @include desktop {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &-TierPrices {
        margin-bottom: 30px;

        &:empty {
            display: none;
        }

        .TierPrices-Item {
            font-size: 15px;
            line-height: 22px;
            font-weight: $font-weight-regular;
            letter-spacing: $letter-spacing2;
            color: var(--color-gray1);
            margin-bottom: 10px;

            strong {
                font-weight: $font-weight-medium;
                letter-spacing: $letter-spacing9;
            }
        }
    }

    &-Brand {
        font-weight: $font-weight-bold;
        opacity: .48;
        font-size: 12px;
        line-height: 16px;
    }

    &-SocialWrapper {
        display: flex;
        padding: 20px 15px;
        background : var(--color-gray5);

        @include mobile {
            margin-top: 30px;
        }

        .ProductWishlistButton {
            width: auto;
            height: auto;
            display: block;

            .Button {
                &_isHollow:not([disabled]) {
                    &:hover {
                        @include desktop {
                            background: transparent !important;
                        }
                    }
                }
            }

            button {
                height: auto;
                line-height: normal;
                background: none;
                display: flex;
                align-items: center;

                &:hover {
                    > span {
                        @include desktop {
                            text-decoration: underline;
                        }
                    }

                    .ProductWishlistButton-Heart {
                        &::before {
                            @include desktop {
                                color: var(--color-gray9);
                            }
                        }
                    }
                }

                .ProductWishlistButton-Heart {
                    display: flex;
                    align-items: center;
                }
            }

            span {
                display: block;
                font-size: 15px;
                line-height: 19px;
                font-weight: $font-weight-medium;
                letter-spacing: $letter-spacing;
                color: var(--color-gray9);
                margin-left: 10px;
                text-transform: capitalize;
            }
        }
    }

    &-Title {
        margin-bottom: 10px;
    }

    &-dCoded {
        font-size: 15px;
        font-weight: $font-weight-medium;
        letter-spacing: $letter-spacing9;
        color: var(--primary-light-color);
    }

    &-ShortDescription {
        font-size: 14px;
        line-height: 20px;
        margin-block: 16px;
        display: none;

        div[itemprop="description"] li::before {
            position: static;
        }
    }

    &-Sku {
        display: none;
    }

    &-rank,
    &-Author {
        font-size: 12px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing1;
        color: var(--secondary-light-color);
        line-height: 18px;
        margin-bottom: 10px;
    }

    &-Review {
        order: 2;
        font-weight: 700;
        cursor: pointer;
        inset-block-end: -2px;
        font-size: 14px;
        padding-right: 15px;

        &Text {
            display: none;
        }
    }

    &-Section {
        &_type {
            &_sku {
                display: flex;

                .TextPlaceholder {
                    line-height: 30px;
                }
            }

            &_alerts {
                margin-block-start: 10px;
                margin-block-end: 10px;
            }
        }
    }

    &-Schema {
        min-width: 150px;
    }

    &-SchemaUrl {
        display: none;
    }

    &-PriceWrapper {
        display: flex;
        align-items: center;

        @include desktop {
            align-items: baseline;
        }

        .ProductPrice {
            min-height: auto;

            &-PriceBottom {
                margin-bottom: 5px;
            }

            &-PriceValue {
                font-size: 20px;
                font-weight: $font-weight-semibold;
                letter-spacing: 0;
                line-height: 24px;
    
                @include desktop {
                    font-size: 24px;
                    line-height: 30px;
                }
            }
        }

        del {
            width: auto;
        }

        .ProductPrice-HighPrice,
        .ProductActions-discountPer {
            @include h5;

            margin-left: 10px;
        }

        .ProductPrice-HighPrice {
            color: var(--color-gray2);
        }

        .ProductActions-discountPer {
            color: var(--color-orange4);
            margin-bottom: 0;

            @include mobile {
                border: 0;
                padding: 0;
            }
        }

        .ProductPrice-TierPrice {
            @include paragraph-text;

            padding: 0;
            margin: 0;
            border-left: 0;
            margin-bottom: 5px;

            strong {
                font-weight: $font-weight-medium;
                letter-spacing: 0.38px;
            }
        }
    }

    &-AddToCart {
        margin-left: 20px;
    }

    &-ActionButtons {
        display: flex;
    }

    &-ActionsWrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include desktop {
            margin-bottom: 20px;
        }

        p,
        .ProductActions-Stock {
            font-size: 12px;
            font-weight: $font-weight-regular;
            letter-spacing: $letter-spacing1;
            color: var(--secondary-light-color);
            border-left: 1px solid var(--primary-divider-color);
            line-height: 18px;
            padding: 0 15px;
            margin-bottom: 10px;
        }

        .ProductActions {
            &-AuthorsRank {
                @include mobile {
                    padding-left: 0;
                    border-left: 0;
                }
            }

            &-authors {
                font-size: 10px;
                font-weight: $font-weight-semibold;
                letter-spacing: $letter-spacing10;
                color: var(--color-white);
                background: var(--color-gray2);
                text-align: center;
                padding: 4px 7px;
                line-height: 12px;
            }

            &-PriceWrapper {
                @include mobile {
                    margin-top: 20px;
                }

                .ProductActions-PriceWrapper {
                    @include mobile {
                        margin-top: 0;
                    }
                }
            }
        }

        .ProductCompareButton {
            margin-inline-start: auto;
        }

        &_isWithoutPriceTotal .ProductActions-Section_type_sku {
            @include mobile {
                float: end;
            }
        }
    }

    &-AddToCartFixed {
        position: fixed;
        bottom: var(--footer-total-height);
        z-index: 5;
        background-color: var(--color-white);
        width: 100%;
        display: flex;
        padding: 10px 20px;
        left: 0;
        box-shadow: $box-shadow8;

        .AddToCart {
            background-color: var(--button-background);

            @include mobile {
                margin-left: 15px;
            }
        }

        .ProductWishlistButton {
            margin-inline: 18px 4px;
            margin-block: auto;
        }

        .Field {
            &-Wrapper {
                &_type {
                    &_number {
                        display: none;
                    }
                }
            }
        }

        &_inStock {
            .Field {
                &-Wrapper {
                    &_type {
                        &_number {
                            @include mobile {
                                display: flex;
                                margin-top: 0;
                            }

                            &:first-of-type {
                                @include mobile {
                                    margin-block-start: 0;
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .hideOnScroll & {
            transform: translateY(var(--footer-nav-height));
        }

        .ProductActions-Section_type_alerts {
            margin: 0;
            width: 100%;

            .ProductAlerts-Wrapper {
                display: block;
                width: 100%;

                button {
                    margin-left: 0;
                }

                small {
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: $font-weight-regular;
                    letter-spacing: $letter-spacing1;
                    color: var(--secondary-light-color);
                    margin-top: 5px;
                    display: none;

                    @include desktop {
                        display: block;
                    }
                }
            }
        }
    }

    &-AddToCartWrapper {
        align-items: center;
        display: flex;
        margin-top: 30px;
        padding: 20px 15px 0 15px;
        background : var(--color-gray5);

        .AddToCart {
            --hollow-button-background: var(--button-background);
            --hollow-button-hover-background: var(--button-hover-background);
            --hollow-button-hover-border: var(--button-hover-border);
            
            margin-left: 20px;

            @include ultra-narrow-desktop {
                width: 74%;
            }
        }

        .ProductActions-AddToCart {
            margin-inline-end: 16px;

            @include ultra-narrow-desktop {
                order: 10;
                margin-block-start: 24px;
            }
        }

        .ProductCompareButton,
        .ProductWishlistButton {
            margin: 0 16px;
        }

        @include ultra-narrow-desktop {
            justify-content: space-between;
        }

        &_isPrerendered {
            @include mobile {
                inset-block-end: 0;
            }
        }

        .Field-ErrorMessages {
            position: absolute;
        }

        .Field-Wrapper_type_number {
            display: none;
            margin-top: 0;

            &:first-of-type {
                @include mobile {
                    margin-block-start: 0;
                    margin-top: 0;
                }
            }
        }

        &_inStock {
            .Field-Wrapper_type_number {
                display: block;

                &:first-of-type {
                    margin-block-start: 0;
                }
            }
        }

        .ProductActions-Section_type_alerts {
            margin: 0;
            width: 100%;

            .ProductAlerts-Wrapper {
                display: block;
                width: 100%;

                button {
                    margin-left: 0;
                }

                small {
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: $font-weight-regular;
                    letter-spacing: $letter-spacing1;
                    color: var(--secondary-light-color);
                    margin-top: 5px;
                    display: none;

                    @include desktop {
                        display: block;
                    }
                }
            }
        }
    }

    &-Qty {
        &.Field {
            margin-block: 0;
        }

        @include qty-selector;
    }

    &-AttributesWrapper {
        @include mobile {
            margin-block-end: 16px;
        }

        &.animate {
            animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;
        }
    }

    &-GroupedItems {
        margin-block-start: 24px;
    }

    &-Reviews {
        display: flex;
        padding: 0 15px 0 0;
        margin-bottom: 10px;

        &:empty {
            display: none;
        }
    }

    .ProductConfigurableAttributes-Expandable {
        margin-block-start: 24px;
    }

    .ExpandableContent:first-of-type {
        border-block-start: 0;
    }

    .SalesLabel-SaleItem {
        margin: 25px 10px 10px 0;
        display: inline-block;
        width: auto;

        @include desktop {
            margin: 0 10px 10px 0;
        }

        &:last-of-type {
            margin: 25px 10px 10px 0;

            @include desktop {
                margin: 0 10px 10px 0;
            }
        }
    }
}
